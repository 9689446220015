function NotFound() {
  return (
    <div className="vh85">
      <h2 className="dark-green">404 Error - Page Not Found</h2>
      <h3 className="dark-green">Please Return to the Home Page</h3>
    </div>
  );
}

export default NotFound;
